<template>
  <div class="card">
    <div class="card-header">
      <h2 class="card-title">Billing History</h2>
    </div>
    <div class="card-body">
      <table class="table">
        <thead>
          <tr>
            <th>Status</th>
            <th>Invoice #</th>
            <th>Created</th>
            <th>Paid</th>
            <th>Payment Type</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="invoice in invoices" :key="`invoice-${invoice.invoiceId}`">
            <td>
              <span
                :class="[
                  'badge',
                  'text-uppercase',
                  `bg-${paymentColor(invoice)}`,
                ]"
              >
                {{ paymentStatus(invoice) }}
              </span>
            </td>
            <td>
              <a
                target="_blank"
                :href="`/billing/invoice-detail?iid=${
                  invoice.invoiceId
                }${invoice.invoiceGuid.substring(0, 3)}&ioid=${
                  invoice.insertionOrder.insertionorderid
                }`"
              >
                Invoice #{{ invoice.invoiceId }}
              </a>
            </td>
            <td>{{ prettyDate(invoice.createDate) }}</td>
            <td v-if="paymentStatus(invoice) == 'paid'">
              {{ prettyDate(invoice.actualPaymentDate) }}
            </td>
            <td v-else-if="paymentStatus(invoice) == 'processing'">
              Processing payment
            </td>
            <td v-else>No payment received</td>
            <td>{{ invoice.paymentType }}</td>
            <td>{{ money(invoice.amount) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import BillingService from "../../../services/BillingService";
import { format, parseJSON } from "date-fns";

export default {
  name: "account.billing.invoices",
  data() {
    return {
      invoices: [],
    };
  },
  methods: {
    prettyDate(date) {
      return format(parseJSON(date), "MM/dd/yyyy");
    },
    money(number) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(number);
    },
    paymentStatus(io) {
      if (io.insertionOrder.installmentstatus == "c" || io.isVoid) {
        return "voided";
      } else if (io.actualPaymentDate) {
        return "paid";
      } else if (!io.actualPaymentDate && io.insertionOrder.confirmdate) {
        return "processing";
      } else {
        return "outstanding";
      }
    },

    paymentColor(io) {
      const status = this.paymentStatus(io);
      if (status == "paid") {
        return "green";
      } else if (status == "processing") {
        return "yellow";
      } else if (status == "voided") {
        return "gray";
      } else {
        return "red";
      }
    },
  },
  async mounted() {
    this.invoices = await BillingService.getInvoices(this.$auth.activeCompany);
  },
};
</script>
